/* -------------------------------------------
    Coming Soon
    - Coming soon countdown
    - Coming soon section
---------------------------------------------- */


// Coming Soon countdown
.countdown-coming {
    text-align: left;

    .countdown-row {
        display: flex;
        justify-content: center;
        line-height: 1;
    }
    .countdown-amount {
        display: block;
        padding: 0 4.2rem;
        font-size: 3rem;
        color: #222;
        letter-spacing: -.025em;
    }
    .countdown-period {
        display: block;
        font-size: 1.6rem;
        text-align: center;
        text-transform: lowercase;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .countdown-section:not(:first-child)::before {
        content: ':';
        position: absolute;
        color: #ccc;
        font-size: 3rem;
        line-height: .9;
        transform: translate(-3px);
    }
}

@media (max-width: 479px), (min-width: 768px) and (max-width: 991px) {
    .countdown-coming .countdown-amount {
        padding: 0 2.1rem;
    }
}

// Coming Soon Section
.coming-section {
    .row {
        min-height: 100vh;

        @include only-for-ie() {
            height: 1px;
        }
    }

    h1 {
        font-size: 5rem;
        font-weight: 900;
    }
    p {
        line-height: 2.15;
        letter-spacing: -.01em;
    }
    form {
        position: relative;
    }
    .form-control {
        font-weight: 300;
        border-color: #dadada;
        padding: 1.15rem 13rem 1.15rem 1.5rem;
    }
    .btn {
        position: absolute;
        right: 0;
        top: 0;
        padding: 1.55rem 2.85rem;
        font-size: 1.3rem;
        border-radius: 0;
        &:hover {
            background: $primary-color;
            color: #fff;
        }
    }
    .social-link {
        border-width: 1px;
        line-height: 28px;
        margin-right: 5px;
    }
}
@include mq(xs, max) {
    .coming-section {
        padding-bottom: 1px;
    }
    .countdown-coming {
        .countdown-amount {
            font-size: 2.7rem;
        }
        .countdown-period {
            font-size: 1.3rem;
        }
    }
}