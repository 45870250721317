.skel-megamenu,.skel-menu,.skeleton-body .skel-cat,.skeleton-body .skel-post,.skeleton-body .skel-post-list,.skeleton-body .skel-post-mask,.skeleton-body .skel-pro,.skeleton-body .skel-pro-gallery,.skeleton-body .skel-pro-list,.skeleton-body .skel-pro-search,.skeleton-body .skel-pro-summary,.skeleton-body .skel-pro-tabs,.skeleton-body .widget-2 {
    position: relative;
    overflow: hidden;
    width: 100%
}

.skel-megamenu:before,.skel-menu:before,.skeleton-body .skel-cat:before,.skeleton-body .skel-post-list:before,.skeleton-body .skel-post-mask:before,.skeleton-body .skel-post:before,.skeleton-body .skel-pro-gallery:before,.skeleton-body .skel-pro-list:before,.skeleton-body .skel-pro-search:before,.skeleton-body .skel-pro-summary:before,.skeleton-body .skel-pro-tabs:before,.skeleton-body .skel-pro:before,.skeleton-body .widget-2:before {
    content: "";
    display: block;
    width: 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(#f4f4f4 100%,transparent 0),linear-gradient(#f4f4f4 100%,transparent 0),linear-gradient(#f4f4f4 100%,transparent 0),linear-gradient(#f4f4f4 100%,transparent 0),linear-gradient(#f4f4f4 100%,transparent 0),linear-gradient(#f4f4f4 100%,transparent 0),linear-gradient(#f4f4f4 100%,transparent 0),linear-gradient(#f4f4f4 100%,transparent 0),linear-gradient(#f4f4f4 100%,transparent 0),linear-gradient(#f4f4f4 100%,transparent 0),linear-gradient(#f4f4f4 100%,transparent 0),linear-gradient(#f4f4f4 100%,transparent 0),linear-gradient(#f4f4f4 100%,transparent 0),linear-gradient(#f4f4f4 100%,transparent 0),linear-gradient(#f4f4f4 100%,transparent 0),linear-gradient(#f4f4f4 100%,transparent 0),linear-gradient(#f4f4f4 100%,transparent 0),linear-gradient(#f4f4f4 100%,transparent 0),linear-gradient(#f4f4f4 100%,transparent 0)
}

.skel-megamenu:after,.skel-menu:after,.skeleton-body .skel-cat:after,.skeleton-body .skel-post-list:after,.skeleton-body .skel-post-mask:after,.skeleton-body .skel-post:after,.skeleton-body .skel-pro-gallery:after,.skeleton-body .skel-pro-list:after,.skeleton-body .skel-pro-search:after,.skeleton-body .skel-pro-summary:after,.skeleton-body .skel-pro-tabs:after,.skeleton-body .skel-pro:after,.skeleton-body .widget-2:after {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    background-image: linear-gradient(90deg,rgba(255,255,255,0) 20%,rgba(255,255,255,0.8) 50%,rgba(255,255,255,0) 80%);
    animation: skeletonloading 1.5s infinite
}

.skeleton-body .widget-2:first-of-type {
    padding-top: 0;
    margin-top: 0
}

.skeleton-body .widget-2:before {
    height: 630px;
    background-size: 100% 30px,70% 20px,50% 20px,80% 20px,40% 20px,30% 20px,50% 20px,100% 30px,70% 20px,50% 20px,80% 20px,40% 20px,30% 20px,50% 20px;
    background-position: 0 10px,0 65px,0 105px,0 145px,0 185px,0 225px,0 265px,0 325px,0 380px,0 420px,0 460px,0 500px,0 540px,0 580px
}

.skeleton-body .skel-pro:before {
    padding-top: calc(338% / 3 + 119px);
    background-size: 100% calc(100% - 119px),55% 12px,85% 18px,55% 20px,40% 15px;
    background-position: 0 0,0 calc((100% - 119px) + 22px),0 calc((100% - 119px) + 48px),0 calc((100% - 119px) + 75px),0 calc((100% - 119px) + 100px)
}

.skeleton-body .skel-pro-search {
    padding: 1.5rem 0;
    border-bottom: 1px solid #ebebeb
}

.skeleton-body .skel-pro-search:before {
    padding-top: 60px;
    background-size: 60px 60px,60% 30%,30% 30%;
    background-position: 0 0,75px 5px,75px 80%
}

.skeleton-body .skel-pro-search:last-child {
    border-bottom: 0
}

.skeleton-body .skel-pro-summary:before {
    min-height: 500px;
    background-size: 55% 25px,20% 25px,70% 30px,40% 18px,30% 30px,40% 15px,100% 14px,100% 14px,100% 14px,40% 14px,66% 30px,70% 30px,100% 1px,70% 45px,100% 1px,50% 30px;
    background-position: 0 0,100% 0,0 34px,0 75px,0 115px,0 155px,0 190px,0 215px,0 240px,0 265px,0 310px,0 350px,0 400px,0 420px,0 485px,0 506px
}

.skel-quick.skeleton-body .skel-pro-gallery:before {
    background-size: 100% 100%;
    background-position: 0 0
}

.skel-quick.skeleton-body .skel-pro-summary:before {
    background-size: 70% 30px,40% 18px,30% 30px,40% 15px,100% 14px,100% 14px,100% 14px,40% 14px,100% 1px,70% 45px,100% 1px,50% 30px;
    background-position: 0 20px,0 60px,0 100px,0 140px,0 175px,0 200px,0 225px,0 250px,0 285px,0 305px,0 370px,0 390px;
    min-height: 435px
}

.skeleton-body .skel-pro-tabs:before {
    min-height: 200px;
    background-size: 120px 25px,200px 25px,100px 25px,100% 1px,100% 15px,100% 15px,100% 15px,80% 15px;
    background-position: 0 0,140px 0,360px 0,0 35px,0 55px,0 85px,0 115px,0 145px
}

// .skeleton-body .skel-post-list:before,.skeleton-body .skel-post:before {
//     padding-top: calc(56.25% + 258px);
//     background-size: 100% calc(100% - 258px),40% 20px,60% 35px,20% 20px,100% 70px,20% 20px;
//     background-position: 0 0,0 calc(100% - 228px),0 calc(100% - 178px),0 calc(100% - 148px),0 calc(100% - 68px),0 calc(100% - 38px)
// }

.mfp-product.skeleton-body .skel-pro-gallery:before {
    padding-top: 112.5%;
    background-size: 100% 100%;
    background-position: 0 0
}

.mfp-product.skeleton-body .skel-pro-summary:before {
    padding-top: 112.5%;
    min-height: auto;
}

.skeleton-body .skel-post-list:before,.skeleton-body .skel-post:before {
    padding-top: calc(56.25% + 200px);
    background-size: 100% calc(100% - 200px), 40% 19px, 60% calc(3% + 10px), 100% 15px, 100% 15px, 67% 15px, 20% 20px;
    background-position: 0 0, 0 calc(100% - 170px), 0 calc(100% - 143px), 0 calc(100% - 122px), 0 calc(100% - 102px), 0 calc(100% - 82px), 0 calc(100% - 54px);
}
.skeleton-body .skel-cat:before,.skeleton-body .skel-post-mask:before {
    padding-top: 100%;
    background-size: 100% 100%;
    background-position: 0 0
}

.skeleton-body .skel-pro-gallery:before {
    padding-top: 112.5%;
    background-size: 100% 80%,23% 18.5%,23% 18.5%,23.5% 18.5%,23.7% 18.5%;
    background-position: 0 0,0 100%,33% 100%,66% 100%,100% 100%
}

.skel-quick.skeleton-body .skel-pro-gallery:before {
    padding-top: 112.5%
}

.skeleton-body .cols-sm-2>.skel-pro-gallery {
    max-width: none;
    flex: 0 0 100%
}

.skeleton-body .cols-sm-2>.skel-pro-gallery:before {
    padding-top: 112.5%;
    background-size: calc(50% - 10px) calc(50% - 10px),calc(50% - 10px) calc(50% - 10px),calc(50% - 10px) calc(50% - 10px),calc(50% - 10px) calc(50% - 10px);
    background-position: 0 0,100% 0,0 100%,100% 100%
}

.skeleton-body .product-masonry-type>.skel-pro-gallery:before {
    background-size: 100% calc(42.75% - 15px),calc(33.3333% - 15px) calc(13.6% - 15px),calc(66.6667% - 5px) calc(28.57% - 15px),calc(66.6667% - 10px) calc(28.57% - 15px),calc(33.3333% - 10px) calc(13.6% - 15px);
    padding-top: calc(262.5% + 40px);
    background-position: 0 0,0 57.8%,100% 59.5%,0 99.5%,100% 90.2%
}

.skeleton-body.sticky-info .skel-pro-gallery:before {
    padding-top: calc(450% + 60px);
    background-size: 100% calc(25% - 15px),100% calc(25% - 15px),100% calc(25% - 15px),100% calc(25% - 15px);
    background-position: 0 0,0 33.333%,0 66.667%,0 100%
}

.skeleton-body .pg-gallery .skel-pro-gallery:before {
    padding-top: calc((100% - 40px) * 9 / 24);
    background-size: 32% 100%,32% 100%,32% 100%;
    background-position: 0 0,50% 0,100% 0
}

.skeleton-body.sticky-both .skel-pro-gallery:before {
    padding-top: calc(450% + 60px);
    background-size: 100% calc(25% - 15px),100% calc(25% - 15px),100% calc(25% - 15px),100% calc(25% - 15px);
    background-position: 0 0,0 33.333%,0 66.667%,0 100%
}

.skeleton-body .skel-pro-list:not(.skel-pro):before {
    padding-top: max(25%, 11.3rem);
    background-size: 10rem 11.3rem, 100% 14px, calc(50% - 7.5rem) 14px, calc(80% - 11rem) 16px, calc(86% - 11rem) 12px;
    background-position: 0 50%, 11rem calc(50% - 35px), 11rem calc(50% - 14px), 11rem calc(50% + 12px), 11rem calc(50% + 38px);
}

@keyframes skeletonloading {
    0% {
        transform: skewX(-45deg) translateX(-80%)
    }

    to {
        transform: skewX(-45deg) translateX(80%)
    }
}

.mfp-container .mfp-content .quickView-content .skel-pro-single {
    margin-bottom: -2rem
}

.post-mask.skeleton-body:before {
    content: none
}

@media (max-width: 767px) {
    .skeleton-body .pg-gallery .skel-pro-gallery:before {
        padding-top:calc((100% - 20px) * 9 / 16);
        background-size: calc(50% - 10px) 100%,calc(50% - 10px) 100%;
        background-position: 0 0,100% 0
    }

    .skeleton-body .skel-pro-gallery {
        margin-bottom: 2rem
    }

    .skel-quick>:not(.mfp-s-error)>.mfp-preloader {
        position: relative;
        top: -100%;
        transform: none;
        width: 100%;
        left: 0
    }
}

@media (min-width: 767px) {
    .skeleton-body .skel-post-list:before {
        padding-top:0;
        min-height: 250px;
        background-size: 41% 100%,20% 15px,40% 25px,56.2% 75px,10% 22px;
        background-position: 0 0,54.75% 40px,73% 65px,100% 105px,48.6% 195px
    }
}

@media (max-width: 575px) {
    .skeleton-body .pg-gallery .skel-pro-gallery:before {
        padding-top:calc(100% * 9 / 8);
        background-size: 100% 100%;
        background-position: 0 0
    }

    .skeleton-body .cols-sm-2>.skel-pro-gallery:before {
        padding-top: calc(450% + 60px);
        background-size: 100% calc(25% - 15px),100% calc(25% - 15px),100% calc(25% - 15px),100% calc(25% - 15px);
        background-position: 0 0,0 33.333%,0 66.667%,0 100%
    }
}

@media (min-width: 576px) {
    .skeleton-body .skel-pro-list:before {
        padding-top:calc(max(25%, calc(338 / 300 * 280px)));
        background-size: 28rem calc(338 / 300 * 280px),20% 10px,50% 18px,25% 20px,25% 12px,calc(100% - 28rem) 50px,123px 50px;
        background-position: 0 50%,30rem calc(50% - 95px),30rem calc(50% - 73px),30rem calc(50% - 46px),30rem calc(50% - 21px),30rem calc(50% + 20px),30rem calc(50% + 95px)
    }
}

@media (min-width: 576px) and (max-width:991px) {
    .skeleton-body.sticky-both>:not(.col-md-6) .skel-pro-gallery:before {
        padding-top:112.5%;
        min-height: 0;
        background-size: calc(50% - 10px) calc(50% - 10px),calc(50% - 10px) calc(50% - 10px),calc(50% - 10px) calc(50% - 10px),calc(50% - 10px) calc(50% - 10px);
        background-position: 0 0,100% 0,0 100%,100% 100%
    }
}

@media (min-width: 992px) {
    .skeleton-body .pg-vertical>.skel-pro-gallery:before {
        padding-top:calc((100% - 119px) * 9 / 8);
        background-size: calc(100% - 119px) 100%,109px 23.8%,109px 23.8%,109px 23.8%,109px 23.8%;
        background-position: 119px 0,0 0,0 33%,0 66%,0 100%
    }

    .skeleton-body.product-single>:not(.col-md-6) .skel-pro-summary:before {
        min-height: 285px;
        background-size: 30% 30px,25% 18px,20% 15px,48% 14px,48% 14px,48% 14px,20% 14px,30% 30px,40% 30px,40% 30px,49.6% 1px,40% 45px,49.6% 1px,30% 30px;
        background-position: 0 0,0 40px,0 65px,0 100px,0 125px,0 150px,0 175px,72% 0,84% 50px,84% 100px,100% 150px,84% 170px,100% 235px,72% 255px
    }

    .skeleton-body.sticky-both>:not(.col-md-6) .skel-pro-summary:before {
        padding-top: calc(225% + 60px);
        background-size: 20% 30px,24% 30px,15% 18px,19% 15px,22% 36px,24% 16px,24% 14px,24% 14px,15% 14px,24% 36px,24% 36px,24% 1px,15% 45px,24% 45px,24% 1px,24% 30px;
        background-position: 0 0,0 40px,0 80px,0 105px,0 140px,0 195px,0 220px,0 245px,0 270px,100% 0,100% 50px,100% 100px,89.4% 120px,100% 185px,100% 250px,100% 270px
    }

    .skeleton-body.sticky-both .woocommerce-product-gallery {
        position: absolute;
        left: 50%;
        transform: translateX(-50%)
    }
}

@media (min-width: 768px) {
    .skel-quick.skeleton-body .skel-pro-summary {
        padding-left:1rem
    }
}

.skel-menu:before {
    height: calc(100% - 30px);
    margin: 15px 0;
    background-size: 50% 20px,60% 20px,40% 20px,50% 20px,70% 20px,60% 20px,65% 20px;
    background-position: 30px 0,30px 16%,30px 33%,30px 50%,30px 66%,30px 83%,30px 100%
}

.skel-megamenu:before {
    height: calc(100% - 30px);
    width: calc(100% - 40px);
    margin: 15px 20px;
    background-size: 28% 20px,23% 20px,25% 20px,20% 20px,30% 20px,25% 20px,26% 20px,28% 20px,20% 20px,28% 20px,23% 20px,25% 20px,20% 20px,30% 20px,25% 20px,26% 20px,28% 20px,20% 20px,30% 100%;
    background-position: 0 0,0 12.5%,0 25%,0 37.5%,0 50%,0 62.5%,0 75%,0 87.5%,0 100%,48.61% 0,45.45% 12.5%,46.66% 25%,43.75% 37.5%,50% 50%,46.66% 62.5%,47.3% 75%,48.61% 87.5%,43.75% 100%,100% 0
}
