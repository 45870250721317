/* -------------------------------------------
    Element List
---------------------------------------------- */

$elements : (accordian, banner, blog, button, cta, icon-box, portfolio, category, product, tab, testimonial, title, typography, video, icon);

.element-section {
    padding: 10.5rem 0 13.5rem;
    background-color: #F3F6F9;
    p {
        font-size: 1.6rem;
        line-height: 1.4;
    }
    .title {
        font-size: 4rem;
    }
}

.elements {
    > div {
        margin-bottom: 3rem;
        transition: transform .3s;
        > a {
            display: block;
        }
        @for $i from 1 through 15 {
            .element-#{ nth( $elements, $i ) } {
                .element-img {
                    background-position: calc(-180px * ( #{$i} - 1 )) 0;
                }
        
                .element-hover-img { 
                    background-position: calc(-180px * #{$i} + 90px) 0;
                }
            }
        }
        &:hover {
            transform: scale( 1.3 );
            z-index: 1;
        }
    }
}
// Element
.element {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3.4rem 0 2.6rem;
    background-color: $white-color;
    border-radius: 1rem;
    box-shadow: 0 2rem 3rem rgba(0,0,0,0.05), 0 0 5px rgba(0,0,0,0.05);
    transition: transform .3s;

    svg {
        fill: $body-color;
    }

    &.element-icon svg {
        margin-top: -1.1rem;
    }
    
    .element-img {
        width: 90px;
        height: 70px;
        background-image: url(../../images/elements.png);
        background-repeat: no-repeat;
        transition: opacity .3s, visibility .3s;
    }

    .element-hover-img {
        position: absolute;
        width: 90px;
        height: 70px;
        left: 50%;
        top: 1.2rem;
        background-image: url(../../images/elements.png);
        transform: translateX(-50%);
        visibility: hidden;
        opacity: 0;
        transition: opacity .3s, visibility .3s;
    }

    p {
        margin: 1.4rem 0 0;
        text-align: center;
        color: #222;
        font: {
            size: 1.6rem;
            weight: 600;
        }
        line-height: 1.2;
        text-transform: uppercase;
    }

    &:hover,
    &.active {
        .element-img {
            opacity: 0;
            visibility: hidden;
        }
        .element-hover-img {
            opacity: 1;
            visibility: visible;
        }
    }
}

@include mq(sm,max) {
    .element-section .element-type {
        max-width: 30rem;
        margin: 0 auto;
    }
    .elements > div:hover {
        transform: scale(1.05);
    }
}