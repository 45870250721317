.filterDivContainer{
    display: flex;
    flex-direction: column;
}
.formElement{
        /* border: 1px solid; */
        width: 10%;
        display: inline-block;
}
.formDiv{
    margin-bottom: 2%;
    display: flex;
    align-items: center;
}
@media (max-width: 768px) {
    .formElement{
        width: 15%;
    }
}